.App {
  text-align: center;
  .logotype {
    position: absolute;
    top: 6vh;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    z-index: 99;
    img {
      width: 40vw;
      height: auto;
      @media (min-width: 1208px) {
        width: 9vw;
      }
    }
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;
  background-position: center center;
  opacity: 1;
  transition: all 0.5s ease;
  position: relative;
  @media (min-width: 1208px) {
    min-height: 100vh;
  }
  .content {
    position: relative;
    z-index: 10;
    max-width: 1800px;
    h1,
    p {
      transition: all 1s ease;
      padding: 20px;
    }
    p {
      padding: 40px;
      padding-top: 0px;
      padding-bottom: 20px;
      @media (min-width: 1208px) {
        padding-top: 10px;
      }
    }
    .add-event {
      transition: all 0.9s ease;
      u {
        transition: all 0.4s ease;
        cursor: pointer;
        display: inline-block;
        &:hover {
          transform: scale(1);
        }
      }
      .react-add-to-calendar__wrapper {
        a {
          color: inherit;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .react-add-to-calendar__button {
          text-decoration: underline;
        }
      }
      .react-add-to-calendar__dropdown {
        animation: fadein 0.9s;
        overflow: hidden;
        ul {
          list-style: none;
          overflow: hidden;
          padding: 0;
          a {
            color: inherit;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    @keyframes fadein {
      from {
        opacity: 0;
        max-height: 0;
      }
      to {
        max-height: 300px;
        opacity: 1;
      }
    }
    button {
      transition: all 1s ease;
    }
    &.start {
      h1 {
        font-size: calc(14px + 4.3vw);
      }
    }
  }
  .bgs-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f1b965;
    background-color: #5c5139;
    z-index: 1;
    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0;
      transition: all 0.8s ease;
      &.showing {
        opacity: 1;
      }
      &.bg-0 {
        background-image: url('./../assets/bgs/mobil/Layer31-min.jpg');
        @media (min-width: 1208px) {
          background-image: url('./../assets/bgs/Layer31-min.jpg');
        }
      }
      &.bg-1 {
        background-image: url('./../assets/bgs/mobil/Layer32-min.jpg');
        @media (min-width: 1208px) {
          background-image: url('./../assets/bgs/Layer32-min.jpg');
        }
      }
      &.bg-2 {
        background-image: url('./../assets/bgs/mobil/Layer33-min.jpg');
        @media (min-width: 1208px) {
          background-image: url('./../assets/bgs/Layer33-min.jpg');
        }
      }
    }
  }
  &.waiting {
    > div {
      h1 {
        transform: translateY(-30px) scale(1.2);
        opacity: 0;
      }
      p,
      .add-event {
        transform: translateY(30px) scale(1.2);
        opacity: 0;
      }
      button {
        opacity: 0;
      }
    }
  }
  .next-button {
    font-family: 'Vanitas';
    position: absolute;
    text-transform: uppercase;
    bottom: 11vh;
    font-size: 0.75em;
    z-index: 20;
    letter-spacing: 1px;
    @media (min-width: 1208px) {
      bottom: 4vh;
      font-size: 0.7em;
    }
  }
}
