@font-face {
  font-family: 'Akzidenz';
  src: url('./assets/fonts/Akzidenz\ Grotesk.otf') format('opentype');
}
@font-face {
  font-family: 'Vanitas';
  font-weight: 600;
  src: url('./assets/fonts/Vanitas\ ExtraBold.otf') format('opentype');
}
body {
  margin: 0;
  font-family: 'Akzidenz', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 400px;
}
h1,
h2,
h3,
h4 {
  font-family: 'Vanitas';
  font-weight: normal;
  text-transform: uppercase;
}
h1 {
  padding: 0 1vw;
  margin: 0 0;
  font-size: calc(14px + 3vw);
  letter-spacing: 1px;
}
p,
.add-event {
  padding: 40px;
  margin: auto;
  font-size: calc(6px + 2.1vw);
  @media (min-width: 1208px) {
    max-width: 45vw;
    min-width: 500px;
    padding: 30px;
    font-size: calc(6px + 0.55vw);
  }
}
.add-event {
  padding-top: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
